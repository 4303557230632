import React, { Component, Fragment } from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import $ from 'jquery'
import { connect } from 'react-redux';
import{ FormControlLabel, Checkbox } from '@mui/material' ;
import { flowCheckFlag } from '../actions/authenticationActions';
import '../styling/login.css';
import '../FontIcons/style.css';
import { Link } from 'react-router-dom'
import { errorMessages, icRelatedUrls } from '../data/constants';
import { app_labels } from '../data/english_label';
import history from './history';
const FormItem = Form.Item;
/* const loginBg = {
   backgroundImage: 'url("../images/loginBG.png")',
   backgroundRepeat: 'no-repeat',
   backgroundSize: 'cover',
   backgroundPosition: 'center',
 }; 
*/

class LoginFormComponent extends Component {
    constructor(props){
        super(props);
        this.state={
            email: '',
            type: 'password',
            msgChange: false,
            checked: false,
            flagUsername: false,
            flagPassword: false,
            flagValidation: false
        }
    }

    componentDidMount() {
        window.onpopstate = () => {
            this.redirect();
        };
        if (localStorage.username) {
            this.setState({ email: localStorage.username })
            this.props.form.setFieldsValue({ username: localStorage.username })
        }
        
        if (window.location.hostname.includes('elan-solutions.elaneasypay')){
            $('.page-title').text("login: Elan Easy Pay").focus();
            document.title = "login: Elan Easy Pay";
        }
        else{
            $('.page-title').text("login: US Bank Instant Card").focus();
            document.title = "login: US Bank Instant Card";
        }
    }

    redirect() {
        history.forward();
        // history.push('/login');
    }

    componentWillMount() {
        window.removeEventListener("popstate", this.redirect);
    }

    handleUserNameValue(e){
        if(e.target.value == ""){
            this.setState({flagUsername: true})
        }else{
            this.setState({flagUsername: false})
        }
    }
    handlePasswordValue(e){
        if(e.target.value == ""){
            this.setState({flagPassword: true})
        }else{
            this.setState({flagPassword: false})
        }
    }

    forgotPassword = () => {
        if (this.state.email !== '') {
            let data = {
                username: this.state.email,
                messageType: "email"
            }
            this.props.sendOTP(data)
            this.props.flowCheckFlag(false)
            history.push('/forgotPassword')
        } else {
            this.props.form.validateFields(['username']);
            document.getElementById("username").focus();
            // ReactDOM.findDOMNode(this.refs.emailItem).focus();
        }
    }

    emailChange = (e) => {
        e.target && this.setState({ email: e.target.value, msgChange: true })
        if(e.target.value == ""){
            this.setState({flagUsername: true})
        }else{
            this.setState({flagUsername: false})
        }
    }
    passwordChange = () => {
        this.setState({ msgChange: true })
    }

    

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <span class='visually-hidden page-title' tabIndex={-1}></span>
                <Row>
                    <Col className="headingStyle-ic">{this.props.label_keys.welcomeInstantCard}
                    </Col>
                </Row><br /><br />
                <Row>
                    <Col className="loginText-ic"><b role="heading" aria-level='1'>{this.props.label_keys.login}</b></Col>
                </Row>
                <Row>
                    {this.state.msgChange ?
                        <Col className="loginMsgText-ic">{this.props.label_keys.pleaseLoginCreds}</Col> :
                        (this.props.credentialsResetSuccess && !this.props.authenticationError) ?
                            <Col role="alert" className="loginMsgText-ic">{this.props.label_keys.pleaseLoginNewPass}</Col> :
                            this.props.credentialsResetError ?
                                <Col style={{ color: "#C92323" }} role="alert"><span className="visually-hidden">Error: </span>{this.props.credentialsResetError.error_description || this.props.credentialsResetError.message || errorMessages.unableToResetPasswordErrorMessage} {this.props.label_keys.pleaseTryAgain}</Col> :
                                this.props.authenticationError ?
                                    <Col className="errorMsg-ic" style={{ color: "rgb(201, 35, 35)" }} role="alert"><span className="visually-hidden">Error: </span>{this.props.authenticationError.error_description || this.props.authenticationError.message || "Authentication failed."}</Col> :
                                    <Col className="loginMsgText-ic">{this.props.label_keys.pleaseLoginCreds}</Col>
                    }
                </Row><br />
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 20 }} lg={{ span: 24 }}>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            this.setState({ msgChange: false })
                            this.props.form.validateFields((err, values) => {
                                if (!err) {

                                    var rmCheck = document.getElementById("rememberMe").checked
                                    this.props.onSubmit(values, rmCheck)
                                }
                            });
                        }}>
                            <FormItem>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: <div id="username-error" className="form-ele-error"><span><span className="visually-hidden">Error: </span>{this.props.label_keys.enterRegisteredEmail}</span></div> }, 
                                    // { pattern: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 
                                    // message: <div id="username-error-2"><span>
                                    //     <span className="visually-hidden">Error: </span>
                                    //     {this.props.label_keys.pleaseValidEmail}</span></div> }
                                      {
                                        validator: (rule, value, callback) => {
                                            let v = String(value)
                                            if(!window.location.hostname.includes("ard.us.bank-dns")){
                                                if (/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,64})+$/.test(v) !== true) {
                                                    this.setState({flagValidation: true})
                                                    return callback(<div id="username-error-2" className="form-ele-error">
                                                    <span className="visually-hidden">Error: </span>
                                                    {this.props.label_keys.pleaseValidEmail}</div>)
                                                } else {
                                                    this.setState({flagValidation: false})
                                                    return callback()
                                                }
                                            }else {
                                                this.setState({flagValidation: false})
                                                return callback()
                                            }
                                        }
                                    }   ],
                                    validateFirst: true
                                })(
                                    <Input required aria-label="email" id='username' data-testid="username" autocomplete="email"
                                        aria-describedby={ (!this.state.flagValidation && !this.state.flagUsername? null :
                                            (this.state.flagValidation && !this.state.flagUsername)?"username-error-2":
                                            "username-error")} 
                                        onChange={this.emailChange} className="loginForm-ic" placeholder={`${this.props.label_keys.email}*`} />
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: <div id="password-error" className="form-ele-error"><span className="visually-hidden">Error: </span>{this.props.label_keys.enterPassword}</div> }],
                                })(
                                    <Input required aria-label="Password" data-testid='password' className="loginForm-ic" autocomplete="current-password" type={this.state.type}
                                     placeholder={`${this.props.label_keys.password}*`} onChange={e => this.handlePasswordValue(e)}
                                     aria-describedby={this.state.flagPassword?"password-error": null} />
                                )}
                                {this.state.type == 'password' ? <Col span={2} className="show-password " role='button' tabindex={0} style={{ left: "93%" }} 
                                onClick={e => { this.setState(() => ({ type: 'text' }))}}
                                onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                        this.setState(() => ({ type: 'text' }))
                                    }
                                }} ><b>{this.props.label_keys.show}</b></Col> : null}
                                {this.state.type == 'text' ? <Col span={2} className="show-password" role='button' tabindex={0} style={{ left: "94%" }} 
                                onClick={e => { this.setState(() => ({ type: 'password' })) } }
                                onKeyUp={e => {
                                    if (e.keyCode === 13) {
                                        this.setState(() => ({ type: 'password' }))
                                    }
                                }} ><b>{this.props.label_keys.hide}</b></Col> : null}
                            </FormItem>
                            <div>
                                <Row>
                                    <Col xs={12} sm={8} md={12} lg={10} xl={8}>
                                        {/* <Checkbox role='checkbox' className="rememberMeCheckbox" aria-labelledby='rememberMeText' id='rememberMe'
                                         aria-checked={this.state.checked} style={{ color: '#19398A' }} onClick={e => {this.setState({checked : !this.state.checked})}}>
                                            <span id='rememberMeText'>{this.props.label_keys.rememberMe}</span>
                                            </Checkbox> */}
                                            <FormControlLabel
                                                control={
                                                <Checkbox checked={this.state.checked} 
                                                    className='rememberMe-ic'
                                                    onChange={e => {this.setState({checked : !this.state.checked})}}
                                                    name={this.props.label_keys.rememberMe}
                                                    style={{ color: '#19398A' }}
                                                    inputProps={{ className:"rememberMeCheckbox-ic", id:'rememberMe' }} 
                                                    TouchRippleProps={{ style: { backgroundColor: 'transparent' }}}/>
                                                }
                                                style={{width: '100%', marginTop: '-6px'}}
                                                label={<span className="rememberMeLabel-ic" style={{marginLeft: '2px'}}>
                                                    {this.props.label_keys.rememberMe}</span>}
                                            />
                                    </Col>
                                    <Col xs={{ span: 10, offset: 2 }} sm={{ span: 7, offset: 9 }} md={{ span: 10, offset: 2 }} lg={{ span: 7, offset: 7 }} xl={{ span: 7, offset: 9 }} style={{float: 'right'}} >
                                        {!window.location.hostname.includes("ard.us.bank-dns") ?
                                            <Link to="/forgotPassword" style={{float: 'right'}}
                                            // onClick={this.forgotPassword}
                                            // onKeyUp={e => {
                                            //     if (e.keyCode === 13) {
                                            //         this.forgotPassword()
                                            //     }
                                            // }} 
                                            className='link-ic'><b>{this.props.label_keys["forgotPassword?"]}</b></Link>
                                            : null
                                        }
                                    </Col>
                                </Row><br /><br />
                                <Row role="list">
                                    <Col span={8} role="listitem"  >
                                        <Link target="_blank" className="link-ic" to={`//${icRelatedUrls.TnCUrl}`}  >
                                            <b style={{ textDecoration: 'underline', fontSize: 13 }}>{this.props.label_keys.termsConditions}</b>
                                            <span className="visually-hidden">&nbsp; opens in new window</span></Link>
                                    </Col>
                                    <Col span={9} role="listitem" >
                                        <Link target="_blank" className="link-ic" to={`//${icRelatedUrls.securityPolicyUrl}`} >
                                            <b style={{ textDecoration: 'underline', fontSize: 13 }}>{this.props.label_keys.securityPrivacyPolicies}</b>
                                            <span className="visually-hidden">&nbsp; opens in new window</span></Link>
                                    </Col>
                                    <Col span={7} style={{float: 'right'}} role="listitem" >
                                        <Link target="_blank" className="link-ic" to={`/openSourceLicenses`} style={{float: 'right'}}>
                                            <b style={{ textDecoration: 'underline', fontSize: 13 }}>{this.props.label_keys.openSourcesLicenses}</b>
                                            <span className="visually-hidden">&nbsp; opens in new window</span></Link>
                                    </Col>

                                </Row><br />
                            </div>
                            <FormItem>
                                <Button  htmlType="submit" className='loginBtnClass-ic' data-testid = "loginButton"
                                    disabled={
                                        !this.state.email || !this.props.form.getFieldValue('password') || this.props.form.getFieldError('username')
                                    }
                                >
                                    {this.props.isAuthenticating === true ?
                                        (
                                            <Fragment>
                                                <span style={{marginLeft: '-7%'}}>{this.props.label_keys.loggingIn}</span>
                                                <Icon type="loading" />
                                            </Fragment>
                                        ) : <span className='loginBtnText-ic'>{this.props.label_keys.login}</span>
                                    }
                                </Button>
                            </FormItem>
                        </Form>
                    </Col>
                </Row>
            </div>
            
        );
    }
}

const WrappedNormalLoginForm = Form.create()(LoginFormComponent);

const mapDispatchToProps = dispatch => {
    return {
        flowCheckFlag: (flag) => {
            dispatch(flowCheckFlag(flag));
        }
    }
}

export default connect(null, mapDispatchToProps)(WrappedNormalLoginForm)
